import Vue from 'vue'
import VueRouter from 'vue-router'

import { sharedRoutes } from '@/@core/utils/router'
import { routerBeforeResolve, routerAfterEach, routerBeforeEach } from '@/@core/utils/utils'

import memberRoutes from "./routes";
import authRoutes from "../shared/auth";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'app-root',
      component: () => import('@/pages/shared/auth/payment-page'),
      meta: {
        layout: 'app',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["member", "anonymous"]
      },
    },
    {
      path: '/auth/login',
      name: 'auth-login',
      component: () => import('@/pages/shared/auth/member-login'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["member", "anonymous"]
      },
    },
    {
      path: '/auth/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/pages/shared/auth/member-forgot-password'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["member", "anonymous"]
      },
    },
    {
      path: '/auth/forgot-password-mfa',
      name: 'auth-forgot-password-mfa',
      component: () => import('@/pages/shared/auth/member-forgot-password/mfa-password-reset'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["member", "anonymous"]
      },
    },
    {
      path: '/auth/register',
      name: 'auth-register',
      component: () => import('@/pages/shared/auth/member-register'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["member"]
      },
    },
    ...authRoutes,
    ...memberRoutes,
    ...sharedRoutes
  ],
})

router.afterEach(routerAfterEach);
router.beforeResolve(routerBeforeResolve)
// eslint-disable-next-line no-return-await
router.beforeEach(async (to, from, next) => await routerBeforeEach(router, to, from, next, "member"))


export default router
