export default class AdminAuthJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setMfaToken(value) {
    localStorage.setItem(this.jwtConfig.storageMFATokenKeyName, value);
  }

  setPasswordResetToken(token) {
    localStorage.setItem(this.jwtConfig.storagePasswordResetTokenKeyName, token)
  }

  setAccountConfirmationToken(token) {
    localStorage.setItem(this.jwtConfig.storageConfirmAccountTokenKeyName, token);
  }

  getAccessToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getMfaAccessToken() {
    return localStorage.getItem(this.jwtConfig.storageMFATokenKeyName);
  }

  getAccountConfirmationToken() {
    return localStorage.getItem(this.jwtConfig.storageConfirmAccountTokenKeyName)
  }

  getPasswordResetToken() {
    return localStorage.getItem(this.jwtConfig.storagePasswordResetTokenKeyName)
  }

  clearAccessToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
  }

  clearAccountConfirmationToken() {
    localStorage.removeItem(this.jwtConfig.storageConfirmAccountTokenKeyName);
  }

  clearPasswordResetToken() {
    localStorage.removeItem(this.jwtConfig.storagePasswordResetTokenKeyName);
  }

  clearPhoneVerificationTimestamp() {
    localStorage.removeItem(this.jwtConfig.storageConfirmPhoneTimestamp);
  }

  setPhoneVerificationToken(token) {
    localStorage.setItem(this.jwtConfig.storageConfirmPhoneToken, token);
  }

  setPhoneVerificationTimestamp(unix_timestamp) {
    localStorage.setItem(this.jwtConfig.storageConfirmPhoneTimestamp, unix_timestamp);
  }

  getPhoneVerificationToken(token) {
    return localStorage.getItem(this.jwtConfig.storageConfirmPhoneToken, token);
  }

  getPhoneVerificationTimestamp() {
    return localStorage.getItem(this.jwtConfig.storageConfirmPhoneTimestamp);
  }

  login(...args) {
    const tokenNames = [
      this.jwtConfig.storageConfirmAccountTokenKeyName,
      this.jwtConfig.storagePasswordResetTokenKeyName,
      this.jwtConfig.storageMFATokenKeyName,
      this.jwtConfig.storageTokenKeyName,
      this.jwtConfig.storageMFADeactivationToken,
      this.jwtConfig.storageMFAPasswordResetToken,
      this.jwtConfig.storageRefreshTokenKeyName
    ];
    tokenNames.forEach(tokenName => {
      localStorage.removeItem(tokenName);
    })

    return this.axiosIns.post(this.jwtConfig.adminAuth.loginEndpoint, ...args)
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.logoutEndpoint, ...args)
  }

  logPageAccess(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.logsEndpoint, payload)
  }

  confirmTokenValidity(tokenType) {
    let token = null;
    if (tokenType === "web_token") {
      this.clearPasswordResetToken();
      this.clearAccountConfirmationToken();
      token = this.getAccessToken()
    } else if (tokenType === "password_reset_token") {
      this.clearAccessToken();
      this.clearAccountConfirmationToken();
      token = this.getPasswordResetToken()
    } else if (tokenType === "confirm_account_token") {
      this.clearAccessToken();
      this.clearPasswordResetToken();
      token = this.getAccountConfirmationToken()
    }
    return this.axiosIns.post(this.jwtConfig.adminAuth.confirmTokenValidityEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  confirmAccount() {
    this.clearAccessToken();
    this.clearPasswordResetToken();
    return this.axiosIns.post(this.jwtConfig.adminAuth.confirmEmailEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${this.getAccountConfirmationToken()}`
      }
    });
  }

  register(formData) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.registerEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  requestPasswordReset(...args) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.requestPasswordResetEndpoint, ...args);
  }

  resetPassword(...args) {
    this.clearAccessToken();
    this.clearAccountConfirmationToken();
    return this.axiosIns.post(this.jwtConfig.adminAuth.resetPasswordEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${this.getPasswordResetToken()}`
      }
    });
  }

  changePassword(payload) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.changePasswordEndpoint, payload);
  }

  requestPhoneVerification(...args) {
    this.clearPasswordResetToken();
    this.clearAccountConfirmationToken();
    return this.axiosIns.post(this.jwtConfig.adminAuth.requestPhoneVerificationEndpoint, ...args);
  }

  confirmPhoneVerification(...args) {
    this.clearPasswordResetToken();
    this.clearAccountConfirmationToken();
    return this.axiosIns.post(this.jwtConfig.adminAuth.confirmPhoneEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${this.getPhoneVerificationToken()}`
      }
    });
  }

  completeRegistration(formData) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.completeOnboardEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  updateProfile(payload = {}) {
    return this.axiosIns.put(this.jwtConfig.adminAuth.updateUserProfileEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  fetchSupportTickets(params) {
    return this.axiosIns.get(`${this.jwtConfig.adminAuth.userSupportTicketsEndpoint}`, {
      params
    })
  }

  fetchSupportTicket(ticket_id) {
    return this.axiosIns.get(`${this.jwtConfig.adminAuth.userSupportTicketsEndpoint}/${ticket_id}`, {});
  }

  fetchUserNotifications(params) {
    return this.axiosIns.get(`${this.jwtConfig.adminAuth.userNotificationsEndpoint}`, {
      params
    })
  }

  createNewSupportTicket(formData) {
    return this.axiosIns.post(this.jwtConfig.adminAuth.userSupportTicketsEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  getRegistrationSettings() {
    return this.axiosIns.get(`${this.jwtConfig.adminAuth.registrationSettingsEndpoint}`)
  }
}
