import useJwt from '@/auth/jwt/useJwt'


export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userType => {
  if (userType === 'admin') return { name: 'admin-home' }
  if (userType === 'church') return { name: 'church-home' }
  if (userType === 'member') return { name: 'member-home' }
  return { name: 'auth-login' }
}
