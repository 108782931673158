export default {
  namespaced: true,
  state: {
    smsMessage: ""
  },
  getters: {
    smsMessage: (state) => state.smsMessage,
  },
  mutations: {
    UPDATE_SMS_MESSAGE(state, message) {
      state.smsMessage = message
    },
  },
  actions: {}
}
