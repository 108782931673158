export default class MemberJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchTransactions(params = {}) {
    return this.axiosIns.get(`member/transactions`, {
      params
    })
  }

  exportTransactions(params) {
    return this.axiosIns.get(`member/transactions/export/all`, {
      params,
      responseType: "blob"
    })
  }

  downloadOfferingReceipt(id) {
    return this.axiosIns.get(`member/transactions/${id}/download`, {})
  }

  fetchRecurringOfferings(params = {}) {
    return this.axiosIns.get(`member/recurring-offerings`, {
      params
    })
  }

  cancelRecurringOffering(id) {
    return this.axiosIns.post(`member/recurring-offerings/${id}/cancel`, {})
  }
}
