export default class SharedJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchCountries(params) {
    return this.axiosIns.get('countries', { params })
  }

  fetchGeoData(params) {
    return this.axiosIns.get('geo-data', { params })
  }

  fetchChurchRegions() {
    return this.axiosIns.get('regions', {})
  }

  fetchChurchAreas(region) {
    return this.axiosIns.get(`regions/${region}/areas`, {})
  }

  fetchChurchDistricts(region, area) {
    return this.axiosIns.get(`regions/${region}/areas/${area}/districts`, {})
  }

  fetchChurchAssemblies(region, area, district) {
    return this.axiosIns.get(`regions/${region}/areas/${area}/districts/${district}/assemblies`, {})
  }

  fetchBanks(params) {
    return this.axiosIns.get('banks', { params })
  }

  fetchApprovedChurchData(interest, params) {
    return this.axiosIns.get(`church-map/${interest}`, { params })
  }

  fetchOfferingTypes(params = {}) {
    return this.axiosIns.get('offering-types', { params })
  }

  initializeOffering(payload) {
    return this.axiosIns.post('give/initialize', payload)
  }

  verifyPayment(reference) {
    return this.axiosIns.get('give/verify', { params: { reference } })
  }

  verifyId(id) {
    return this.axiosIns.get(`church/verify/${id}`)
  }

  search(search_type, params) {
    return this.axiosIns.get(`search/${search_type}/autocomplete`, {
      params
    })
  }
}
