export default {
  // Auth Endpoints
  logEndpoint: '/admin/auth/login',
  logsEndpoint: '/admin/auth/logs',
  loginEndpoint: '/admin/auth/login',
  logoutEndpoint: '/admin/auth/logout',
  registerEndpoint: '/admin/auth/register',
  updateUserProfileEndpoint: '/admin/auth/profile',
  completeOnboardEndpoint: '/admin/auth/onboard',
  confirmPhoneEndpoint: '/admin/auth/confirm-phone',
  confirmEmailEndpoint: '/admin/auth/confirm-email',
  confirmTokenValidityEndpoint: '/admin/auth/check',
  resetPasswordEndpoint: '/admin/auth/reset-password',
  userNotificationsEndpoint: '/admin/auth/notifications',
  changePasswordEndpoint: '/admin/auth/password/change',
  userSupportTicketsEndpoint: '/admin/auth/support-tickets',
  registrationSettingsEndpoint: '/admin/auth/registration/settings',
  requestPasswordResetEndpoint: '/admin/auth/request-password-reset',
  requestPhoneVerificationEndpoint: '/admin/auth/request-phone-verification',
  checkPasswordResetValidityEndpoint: '/admin/auth/check-password-reset-token',
}
