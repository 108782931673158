export const MUTATE_USER_ABILITIES = `MUTATE_USER_ABILITIES`
export const MUTATE_USER_DATA = `MUTATE_USER_DATA`;
export const MUTATE_LOGIN_STATUS = `MUTATE_LOGIN_STATUS`;
export const MUTATE_PHONE_VERIFICATION_STATUS = `MUTATE_PHONE_VERIFICATION_STATUS`;
export const MUTATE_CLIENT_GROUP = 'MUTATE_CLIENT_GROUP';
export const MUTATE_SETTINGS = 'MUTATE_SETTINGS';
export const MUTATE_EZWICH_VERIFICATION = `MUTATE_EZWICH_VERIFICATION`;
export const MUTATE_ONLINE_USERS = `MUTATE_ONLINE_USERS`;
export const MUTATE_USER_TICKET_NOTIFICATIONS = `MUTATE_USER_TICKET_NOTIFICATIONS`;
export const MUTATE_USER_SYSTEM_NOTIFICATIONS = `MUTATE_USER_SYSTEM_NOTIFICATIONS`;
