import Vue from 'vue'
import ElSelect from "@/libs/element/select";
import ElOption from "@/libs/element/select/src/option.vue";
import ElOptionGroup from "@/libs/element/select/src/option-group.vue";
import ElSelectDropdown from "@/libs/element/select/src/select-dropdown.vue";

Vue.component("el-option", ElOption);
Vue.component("el-option-group", ElOptionGroup);
Vue.component("el-select-dropdown", ElSelectDropdown);
Vue.use(ElSelect);
