export default [
  {
    path: '/auth/register/confirm-email',
    name: 'auth-register-confirm-email',
    component: () => import('@/pages/shared/auth/confirm-email'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["church", "member"]
    }
  },
  {
    path: '/auth/register/closed',
    name: 'auth-no-sign_ups',
    component: () => import('@/pages/shared/auth/register-closed'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["church", "member"],
      redirectIfLoggedIn: true
    },
  },
  {
    path: '/auth/register/success',
    name: 'auth-register-success',
    component: () => import('@/pages/shared/auth/register-success'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["client", "member"]
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/pages/shared/auth/reset-password'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      redirectIfLoggedIn: true,
      roles: ["church", "member", "anonymous"]
    },
  },
  {
    path: '/auth/verify-phone',
    name: 'auth-verify-phone',
    component: () => import('@/pages/shared/auth/verify-phone'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      // requireAuth: true,
      logPageName: "View Verify Phone Page",
      roles: ["church", "member"]
    },
  },
  {
    path: '/auth/church-onboarding',
    name: 'church-onboarding',
    component: () => import('@/pages/shared/auth/onboarding/church'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      logPageName: "Church Onboarding",
      roles: ["church", "member"]
    },
  },
  {
    path: '/auth/account',
    name: 'auth-update-profile-account',
    component: () => import('@/pages/shared/auth/profile'),
    meta: {
      action: 'read',
      resource: 'AuthRoute',
      requireAuth: true,
      logPageName: "View Profile Page",
      roles: ["church", "member"]
    },
  },
  {
    path: '/auth/maintenance-mode',
    name: 'misc-under-maintenance',
    component: () => import('@/pages/shared/auth/maintenance'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["church", "member"],
      redirectIfLoggedIn: true
    },
  },

  //  Security routes
  {
    path: '/security/mfa',
    name: 'security-mfa',
    component: () => import('@/pages/shared/auth/security/mfa'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/mfa-authentication',
    name: 'security-mfa-authentication',
    component: () => import('@/pages/shared/auth/security/authentication'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/mfa-activation',
    name: 'security-mfa-activation',
    component: () => import('@/pages/shared/auth/security/activation'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/authentication-app',
    name: 'security-authentication-app',
    component: () => import('@/pages/shared/auth/security/authentication-app'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/activation-app',
    name: 'security-activation-app',
    component: () => import('@/pages/shared/auth/security/activation-app'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/password/expiry',
    name: 'security-password-expiry',
    component: () => import('@/pages/shared/auth/password-expiration'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["church", "member"]
    },
  },
  {
    path: '/security/password/change',
    name: 'security-password-change',
    component: () => import('@/pages/shared/auth/change-password'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["church", "member"]
    },
  },
]
