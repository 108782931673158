export default {
  // Admin Endpoints
  adminLogsEndpoint: '/admin/logs',
  adminLoansEndpoint: '/admin/loans',
  adminUsersEndpoint: '/admin/users',
  adminChurchMapEndpoint: '/admin/church-map',
  adminOfferingTypesEndpoint: '/admin/offering-types',
  adminChurchesEndpoint: '/admin/churches',
  adminProvidersEndpoint: '/admin/providers',
  adminBlogsEndpoint: '/admin/blogs',
  adminAPIEndpoint: '/admin/api-keys',
  adminSearchEndpoint: '/admin/search',
  adminStoriesEndpoint: '/admin/story',
  adminsEndpoint: '/admin/admin-users',
  adminAuditTrail: '/admin/audit-trail',
  adminEventEndpoint: '/admin/events',
  adminEventCenterEndpoint: '/admin/event-centers',
  adminEventBlockEndpoint: '/admin/event-blocks',
  adminEventAccommodationEndpoint: '/admin/event-accommodations',
  adminBulkSmsEndpoint: '/admin/bulk-sms',
  adminTrusteesEndpoint: '/admin/trustees',
  adminDatasetsEndpoint: '/admin/datasets',
  adminSettingsEndpoint: '/admin/settings',
  adminPoliciesEndpoint: '/admin/policies',
  adminsStartersEndpoint: '/admin/starters',
  adminReferralsEndpoint: '/admin/referrals',
  adminDashboardEndpoint: '/admin/dashboard',
  adminWorkflowsEndpoint: '/admin/workflows',
  adminLoanTermsEndpoint: '/admin/loan-terms',
  adminOMCBranchesEndpoint: '/admin/omc-branch',
  adminCsvUploadsEndpoint: '/admin/csv-uploads',
  adminBankAPIEndpoint: '/admin/banks/api-keys',
  adminPermissionsEndpoint: '/admin/permissions',
  adminGraduateLoanEndpoints: '/admin/graduates',
  adminBankBucketsEndpoint: '/admin/bank-buckets',
  adminTestimonialsEndpoint: '/admin/testimonials',
  adminTransactionsEndpoint: '/admin/transactions',
  adminSettlementsEndpoint: '/admin/settlements',
  adminRecurringOfferingsEndpoint: '/admin/recurring-offerings',
  adminClientGroupsEndpoint: '/admin/client-groups',
  adminSampleCSVFileEndpoint: '/admin/sample-csv-file',
  adminOMCSettlementsEndpoint: '/admin/omc-settlement',
  adminSupportMessageEndpoint: '/admin/support-message',
  adminSupportTicketsEndpoint: '/admin/support-tickets',
  adminPartnerAccountsEndpoint: '/admin/partner-account',
  adminDefaultNoticeEndpoint: '/admin/loan-default-notice',
  adminPartnerAdminsEndpoint: '/admin/partner-administrator',
  adminSendgridTemplatesEndpoint: '/admin/sendgrid/templates',
  adminExportTemplatesEndpoint: "/admin/csv/exports/templates",
  adminExportGraduateLoanEndpoints: '/admin/csv/graduates/export',
  paidLoansStatusIntegrityEndpoint: '/admin/loans/status-integrity',
}
