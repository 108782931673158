export default class ChurchJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  register(formData) {
    return this.axiosIns.post(`/church/auth/register`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  completeRegistration(formData) {
    return this.axiosIns.post(this.jwtConfig.auth.completeOnboardEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  updateProfile(payload = {}) {
    return this.axiosIns.put(this.jwtConfig.auth.updateUserProfileEndpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  addUser(payload) {
    return this.axiosIns.post(`church/users`, payload, {});
  }

  fetchUsers(params = {}) {
    return this.axiosIns.get(`church/users`, {
      params
    })
  }

  fetchUser(id) {
    return this.axiosIns.get(`church/users/${id}`, {});
  }

  updateUser(id, payload) {
    return this.axiosIns.put(`church/users/${id}`, payload, {});
  }

  exportUsers(params = {}) {
    return this.axiosIns.get(`church/users/export/all`, {
      params,
      responseType: "blob"
    })
  }

  removeUser(id) {
    return this.axiosIns.delete(`church/users/${id}`, {});
  }

  getRegistrationSettings() {
    return this.axiosIns.get(`${this.jwtConfig.auth.registrationSettingsEndpoint}`)
  }

  fetchDashboardStatsData(params = {}) {
    return this.axiosIns.get(`church/dashboard/stats`, {
      params
    })
  }

  fetchDashboardOfferingsGraphData(params = {}) {
    return this.axiosIns.get(`church/dashboard/offerings-graph`, {
      params
    })
  }

  fetchTransactions(params = {}) {
    return this.axiosIns.get(`church/transactions`, {
      params
    })
  }

  exportTransactions(params) {
    return this.axiosIns.get(`church/transactions/export/all`, {
      params,
      responseType: "blob"
    })
  }

  generateTransactionsReport(params) {
    return this.axiosIns.get(`church/transactions/report`, {
      params
    })
  }

  fetchSettlements(params = {}) {
    return this.axiosIns.get(`church/settlements`, {
      params
    })
  }

  exportSettlement(params) {
    return this.axiosIns.get(`church/settlements/export/all`, {
      params,
      responseType: "blob"
    })
  }

  fetchRecurringOfferings(params = {}) {
    return this.axiosIns.get(`church/recurring-offerings`, {
      params
    })
  }
}
