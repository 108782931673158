import useJwt from '@/auth/jwt/useJwt'
import store from "@/store"

import { get } from "lodash"

export function isExplicitDenied(authorization, urn, actions) {
  return authorization.special_deny.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export function isExplicitAllowed(authorization, urn, actions) {
  return authorization.special_allow.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export const canNavigate = (to) => {
  const { authorization, permissions: church_permissions } = store.getters['auth/userData'];
  return to && to.matched && to.matched.some(route => {
    if (route.meta.church_permission) {
      return church_permissions[route.meta.church_permission] === true
    }

    if (route.meta.permissions) {
      const { permissions = [] } = route.meta;
      const permissionsToEvaluate = !Array.isArray(permissions) ? [permissions] : permissions;
      if (permissionsToEvaluate.length === 0) { return true }

      const { resource } = to.meta;
      if (to.params.id && resource) {
        const urn = `urn:pensa:${resource}:${to.params.id}`;

        if (isExplicitDenied(authorization, urn, permissions)) {
          return false;
        }

        if (isExplicitAllowed(authorization, urn, permissions)) {
          return true
        }
      }

      return permissionsToEvaluate.every(permission => authorization.grants.includes(permission));
    }

    return true;
  })
}

export const _ = undefined


export const checkLoginTokenStatus = async () => {
  const jsonWebTokenValidityResponse = await useJwt.authService.confirmTokenValidity("web_token");
  const { authorized, user, settings, client_group } = get(jsonWebTokenValidityResponse, 'data.data', {});

  return { authorized, user, settings, client_group };
}

