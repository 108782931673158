import store from "@/store"
import useJwt from '@/auth/jwt/useJwt'

import { get } from "lodash"

import { format, formatDistance } from "date-fns"
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate, checkLoginTokenStatus } from '@/libs/acl/routeProtection'

import {
  MUTATE_SETTINGS,
  MUTATE_USER_DATA,
  MUTATE_LOGIN_STATUS,
  MUTATE_PHONE_VERIFICATION_STATUS
} from "@/store/config/mutation-keys"


export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const maskPhoneNumber = (phoneNumber) => {
  const masked = phoneNumber.replace(/[\w\W]/g, "*");
  return `${masked.substr(0, phoneNumber.length - 4)}${phoneNumber.substr(phoneNumber.length - 4)}`;
}

export const formatDate = (date, formatStr = "dd-MM-yyyy") => {
  return format(new Date(date), formatStr);
}

export const formatDateDistance = (date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const formatTime = (timeStr, formatStr = "h:mm:ss a") => {
  const today = new Date()
  const d = new Date(today.getFullYear(), today.getMonth(), today.getDay(), ...timeStr.split(':'))
  return format(d, formatStr);
}

export const formatMoney = (
  amount,
  fraction = 2
) => {
  if (!amount) {
    return 0.00
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: fraction,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export const calculatePercentage = (percent, value) => {
  return (percent / 100) * value
}

export function computeLoanCostAndPenalCharge(loan) {
  const penal_charges = loan.penal_charges || [];
  const total_charge = penal_charges.reduce((accm, penal_charge) => accm + penal_charge.charge, 0);
  return loan.total_loan_cost + total_charge;
}

export function getPasswordErrorMessage(password_settings) {
  if (!password_settings.enable_password_complexity_check) {
    return "";
  }
  const minimumLength = get(password_settings, "minimum_length", 8)

  const messageTexts = [];

  if (get(password_settings, "include_upper_and_lower_cased_characters", true)) {
    messageTexts.push("one uppercase");
    messageTexts.push("one lowercase");
  }
  if (get(password_settings, "include_special_character", true)) {
    messageTexts.push("one special character");
  }
  if (get(password_settings, "include_number", true)) {
    messageTexts.push("one digit");
  }

  let messageString = "";
  messageTexts.forEach(text => {
    if (text === messageTexts[messageTexts.length - 1]) {
      messageString = messageString + " and " + text
    } else {
      messageString = messageString + ", " + text
    }
  })

  return `Your password must contain at least ${minimumLength} characters${messageString}`;
}

async function beforePasswordReset(to, _from, next) {
  const { token: passwordResetToken } = to.query;
  if (!passwordResetToken) {
    store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
    return next({ name: "auth-login" });
  }

  try {
    useJwt.authService.setPasswordResetToken(passwordResetToken);
    await useJwt.authService.confirmTokenValidity("password_reset_token");
    return next();
  } catch (error) {
    return next({ name: "auth-forgot-password", query: { error: "Password reset token has expired" } });
  }
}

async function beforeEnteringAuthenticatedRoute(to, from, next, base = "church") {
  const userIsLoggedIn = store.getters[`auth/isLoggedIn`]

  if (base === "church" && from.name === "church-onboarding" && to.name === "church-home") {
    // just to refetch user; 
    const { user } = await checkLoginTokenStatus()
    store.commit(`auth/${MUTATE_USER_DATA}`, user);
  }

  if (to.name !== from.name) {
    let authorized = userIsLoggedIn;
    let user = store.getters[`auth/userData`];
    let settings = store.getters[`auth/settings`];

    if (!(userIsLoggedIn && user && settings)) {
      console.log("[check token]")
      const authCheckResponse = await checkLoginTokenStatus();
      user = authCheckResponse.user;
      settings = authCheckResponse.settings;
      authorized = authCheckResponse.authorized;

      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
      store.commit(`auth/${MUTATE_USER_DATA}`, user);
      store.commit(`auth/${MUTATE_SETTINGS}`, settings);
    }

    if (!authorized) {
      console.log("[not authorized]")
      return next({ name: 'auth-login' });
    }

    const phoneVerificationStatus = get(user, 'meta.phone_verified', false);
    const { has_completed_onboarding = false, user_type } = user;

    let hasCompletedOnboarding = has_completed_onboarding;

    if (user_type === "church") {
      hasCompletedOnboarding = get(user, 'church_profile.has_completed_onboarding', false)
    }

    const requirePhoneVerification = get(settings, 'security_settings.require_church_phone_verification', false);
    store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, phoneVerificationStatus);

    if (!phoneVerificationStatus && requirePhoneVerification) {
      return next({ name: 'auth-verify-phone' });
    }

    if (base === "church" && user_type === "church" && !hasCompletedOnboarding) {
      const onboarding_screens = ["church-onboarding"];
      if (!onboarding_screens.includes(to.name)) {
        return next({ name: 'church-onboarding' });
      }
    }

    if (base === "church" && hasCompletedOnboarding && user.user_type === "church") {
      const approval_status = get(user, 'church_profile.status');
      if (approval_status === 'pending' && to.name !== "pending-church-verification") {
        return next({ name: 'pending-church-verification' })
      }

      if (approval_status === 'declined' && to.name !== "declined-church-verification") {
        return next({ name: 'declined-church-verification' })
      }

      if (approval_status === "approved" && to.name === "pending-church-verification") {
        return next("/");
      }
    }

    const requireMFA = get(settings, 'security_settings.require_admin_mfa', false);
    if (!user.mfa_conf && requireMFA && to.name !== "security-mfa") {
      console.log("[to security mfa]")
      sessionStorage.setItem("last_page_accessed", to.path)
      return next({ name: "security-mfa" })
    }

    // const daysBeforePasswordExpire = user.days_before_password_expire;

    // if (daysBeforePasswordExpire <= 0 && to.name !== 'security-password-change') {
    //   console.log("[to change password]")
    //   return next({ name: 'security-password-change' })
    // }

    /**
     * redirect user to dashboard.
     */
    if (to.name === "app-root") {
      console.log("[to home]")
      return next('/')
    }

    // check is user has access to route.
    if (canNavigate(to, user.user_type)) {
      console.log("[to proceed]")
      return next();
    }

    return next({ name: 'misc-not-authorized' });
  }

  return next();
}

export function routerBeforeResolve(to, _from, next) {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.start()
  }

  next()
}

export async function routerAfterEach(to) {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line no-undef
  NProgress.done()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  try {
    const requireAuth = get(to, 'meta.requireAuth');
    const hasLogPageName = get(to, 'meta.logPageName');
    const isLoggedIn = isUserLoggedIn();

    if (requireAuth && hasLogPageName && isLoggedIn) {
      await useJwt.authService.logPageAccess({
        route: to.path,
        comment: hasLogPageName
      });
    }
  } catch (error) {
    //
  }
}

export async function routerBeforeEach(router, to, from, next, base = "church") {
  try {
    console.log('base', base)
    const { token: hasAccountOrPasswordConfirmationToken } = to.query;

    const toPathRequiresAuth = get(to, 'meta.requireAuth', false);
    const redirectIfLoggedIn = get(to, 'meta.redirectIfLoggedIn', false);

    const isLoggedIn = isUserLoggedIn();

    const routesRequiringPasswordSettings = [
      "auth-register",
      "auth-reset-password",
      "auth-forgot-password-mfa"
    ];

    if (routesRequiringPasswordSettings.includes(to.name)) {
      const response = await useJwt.authService.getRegistrationSettings();
      const { password_complexity_settings } = response.data.data;
      store.commit(`auth/${MUTATE_SETTINGS}`, { password_complexity_settings });
    }

    /**
     * password reset route handle
     */
    if (hasAccountOrPasswordConfirmationToken && to.name === "auth-reset-password") {
      await beforePasswordReset(to, from, next)
    }

    /**
     * this validation is for routes that only allow access if certain prop is not available.
     */
    const supportedRoutesWithPropValidations = [
      "auth-verify-phone",
      "church-onboarding",
      "auth-register-confirm-email"
    ];

    if (supportedRoutesWithPropValidations.includes(to.name) && isLoggedIn) {
      /**
       * a user is not allowed to manually visit 'auth-register-success' manually
       * except they came from 'auth-register'
       */
      if (to.name === "auth-register-confirm-email") {
        if (from.name !== "auth-register" || !sessionStorage.getItem("registration_email")) {
          return next({ name: 'auth-login' });
        }
        return next();
      }

      const { authorized, user } = await checkLoginTokenStatus()
      // console.log("user", user)
      // console.log("---> authorized", authorized);

      if (!authorized) {
        return next({ name: 'auth-login' });
      }

      const phoneNumberVerified = get(user, 'meta.phone_verified', false);
      const { has_completed_onboarding = false, user_type } = user;

      let onboardingCompleted = has_completed_onboarding;

      if (user_type === "church") {
        onboardingCompleted = get(user, 'church_profile.has_completed_onboarding', false);
      }

      if (base === "church" && user_type === "church" && onboardingCompleted && to.name === "church-onboarding") {
        return next("/");
      }

      if (base === "church" && user_type === "church" && phoneNumberVerified && to.name === "auth-verify-phone") {
        return next("/");
      }
    }


    /**
     * path requires authentication and user is logged in.
     */
    if (toPathRequiresAuth && isLoggedIn) {
      await beforeEnteringAuthenticatedRoute(to, from, next, base)
    }

    /**
     * path requires authentication but user is not logged in.
     */
    if (toPathRequiresAuth && !isLoggedIn) {
      return next({
        name: 'auth-login'
      });
    }

    if (!toPathRequiresAuth && isLoggedIn && redirectIfLoggedIn) {
      console.log("---> requires no authentication with redirect and is logged in");
      const { authorized, user, settings } = await checkLoginTokenStatus()

      const in_maintenance_mode = get(settings, 'enable_maintenance_mode', false)
      if (in_maintenance_mode) {
        if (to.name === 'misc-under-maintenance') {
          return next();
        }
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      console.log("---> authorized", authorized);
      if (authorized) {
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
        store.commit(`auth/${MUTATE_USER_DATA}`, user);
        store.commit(`auth/${MUTATE_SETTINGS}`, settings);

        const { name } = getHomeRouteForLoggedInUser(user.user_type);
        const route = base === "member" ? 'member-home' : name
        console.log("route", route)
        const currentUserHomeRoute = router.resolve({ name: route });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name: route, query: get(to, "query") });
        }

        return next({ name: 'misc-not-authorized' })
      }

      // proceed to intended destination because user is not logged in.
      // here user is only redirect if they are logged in.
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false);
      return next();
    }

    return next()
  } catch (error) {
    useJwt.authService.clearAccessToken();

    if (!to.name !== "auth-login") {
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
      next({ name: "auth-login" });
    }
    return next();
  }
}

export function base64toBlob(data) {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  // eslint-disable-next-line prefer-destructuring
  let length = bytes.length;
  const out = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
}
