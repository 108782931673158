

export const sharedRoutes = [
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/pages/shared/error-404'),
    meta: {
      layout: 'full',
      action: 'read',
      resource: 'AuthRoute',
      roles: ["admin", "member", "church"]
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/pages/shared/error-403'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["admin", "member", "church"]
    },
  },
]

export const xyz = ""
