export default [
  {
    path: '/home',
    name: 'member-home',
    component: () => import('@/pages/member/home'),
    meta: {
      action: "read",
      pageTitle: 'Transactions',
      resource: "MemberHomeRoute",
      breadcrumb: [
        {
          text: 'Transactions',
          active: true,
        },
      ],
      roles: ["member", "church"],
      requireAuth: true,
      memberPage: true,
      logPageName: "Member Dashboard",
    },
  },
  {
    path: '/give',
    name: 'member-give',
    component: () => import('@/pages/member/give'),
    meta: {
      action: "read",
      pageTitle: 'Give',
      resource: "MemberHomeRoute",
      breadcrumb: [
        {
          text: 'Give',
          active: true,
        },
      ],
      roles: ["member", "church"],
      requireAuth: true,
      memberPage: true,
      logPageName: "Member Give",
    },
  },
  {
    path: '/recurring-offering',
    name: 'member-recurring-offering',
    component: () => import('@/pages/member/recurring-offering'),
    meta: {
      action: "read",
      pageTitle: 'Recurring offering',
      resource: "MemberHomeRoute",
      breadcrumb: [
        {
          text: 'Recurring offering',
          active: true,
        },
      ],
      roles: ["member", "church"],
      requireAuth: true,
      memberPage: true,
      logPageName: "Member Recurring Offering",
    },
  },
]
